
class ZIDXAccountSites implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSitesContainer";
    }
    getMatchURL(){
        return "/account/sites";
    }
    render(){
        let listView=new ZIDXAccountListView();
        listView.render();
    }
}
